<template>
    <div class="scroll_table">
        <table class="show_header">
            <thead>
                <tr>
                    <th class="selectbox"></th>
                     <th class="userbox">Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>SMS Status</th>
                    <th>SMS</th>
                    <th>Media</th>
                    <th>Timezone</th>
                    <th class="schedule">Schedule</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody v-if="scheduledSms.total">
                <tr v-for="(sms, s) in scheduledSms.data" :key="s">
                    <td>
                        <label :for="`check-${s}`" class="checkbox">
                            <input type="checkbox" :id="`check-${s}`" :value="sms.id" v-model="scheduleIds" hidden>
                            <span><i class="fas fa-check"></i></span>
                        </label>
                    </td>
                    <td>
                        <div class="user_wpr">
                            <div class="user_img">
                                <img :src="sms.contact ? sms.contact.photo : ''" alt="Contact Image">
                            </div>
                            <h4>{{ sms.contact_name }}</h4>
                        </div>
                    </td>
                    <td>
                        <div class="email_wpr">
                            <span class="email_add">{{ sms.contact ? sms.contact.email : '-' }}</span>
                        </div>
                    </td>
                    <td>{{ sms.contact_number }}</td>
                    <td><button :class="sms.contact && sms.contact.sms_enabled ? 'success' : ''">{{ sms.contact && sms.contact.sms_enabled ? 'Enabled' : 'Disabled' }}</button></td>
                    <td>{{ sms.sms }}</td>
                    <td>{{ sms.media ? sms.media : "-" }}</td>
                    <td>{{ sms.time_zone }}</td>
                    <td>{{ moment(sms.schedule).format('ll | LT') }}</td>
                    <td>
                        <button class="success" v-if="sms.in_queue == 1 && sms.expired_time_in_minutes < 15">Sending</button>
                        <button class="failed" v-else-if="sms.in_queue == 2 || (sms.in_queue == 1 && sms.expired_time_in_minutes > 15)">Failed</button>
                        <button v-else>Waiting</button>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="10" class="px-4 text-center empty">
                        <div class="empty_box">
                            <img src="@/assets/images/empty_state.svg">
                            <h4>No Records Found</h4>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import moment from 'moment-timezone'

    export default {
        name: 'Scheduled Sms',

        data () {
            return {
                scheduleIds: [],
                moment
            }
        },

        props: {
            scheduledSms: Object,
            modelValue: [Object, Array],
            updateSelectAll: {
                type: Function,
                default: () => {},
            },
        },

        emit: ['update:modelValue'],

        watch:{
            modelValue: {
                handler (ids) {
                    const vm  = this;
                    vm.scheduleIds = ids;
                },
                deep: true,
            },

            scheduleIds (ids) {
                const vm  = this;

                if (Object.keys(vm.scheduledSms.data).length !== ids.length) {
                    vm.updateSelectAll(0);
                } else {
                    vm.updateSelectAll(1);
                }

                vm.$emit('update:modelValue', ids);
            }
        },
    }
</script>

<style scoped>
    .scroll_table {
        overflow-x: auto;
    }
    .modal.profile_modal .result_wpr table{
        min-width: 1300px !important;
    }
    table button {
        padding: 3px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #f2a31e;
        border-radius: 10px;
        color: #fff;
        margin-left: 7px;
    }

    table button.success{
        background: #00aa14;
    }

    table button.failed{
        background-color: #f04438
    }

    table.standard th {
        font-size: 13px;
        line-height: 16px;
        padding: 15px 12px;
    }

    table.standard td {
        font-size: 13px;
        line-height: 16px;
        padding: 18px 12px;
    }

    table.standard td .user_wpr h4 {
        font-size: 13px;
        line-height: 16px;
        color: #121525;
        font-weight: 400;
        letter-spacing: 0;
    }
    .actions > ul li.optionDrops.sort_list .dropdown_wpr{
        width: auto !important;
    }
</style>
